import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Page from '@atlaskit/page';
import Form from '@atlaskit/form';
import Button from '@atlaskit/button';
import { AutoDismissFlag as Flag, FlagGroup } from '@atlaskit/flag';
import Error from '@atlaskit/icon/glyph/error';
import Tick from '@atlaskit/icon/glyph/check-circle';
import { colors } from '@atlaskit/theme';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import isUndefined from 'lodash/isUndefined';

import { isRequired, isEmail } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { isGuest } from 'utils/request';

import Spinner from 'components/Spinner';
import TextField from 'components/TextField';

import { changeFlags, changeLogin, changePassword, loginRequest, sendOtp } from './actions';

import {
  makeSelectError,
  makeSelectFlags,
  makeSelectLogin,
  makeSelectPassword,
  makeSelectOtpLoading
} from './selectors';

import reducer from './reducer';
import saga from './saga';

import Image from './images/pic.png';
import Image2x from './images/pic-2x.png';

import ButtonField from './styled/ButtonField';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Flex from './styled/Flex';
import FlexItem from './styled/FlexItem';
import P from './styled/P';
import Title from './styled/Title';
import ContactsWrapper from "../IndividualProfile/Login/styled/ContactsWrapper";
import Telegram from "../../promo/Footer/telegram";
import WhatsApp from "../../promo/Footer/whatsapp";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorFlag: [1],
      email: {
        wasChanged: false,
        isInvalid: false,
        value: ''
      },
      password: {
        wasChanged: false,
        isInvalid: false,
        value: ''
      },
      isFormValid: true,
      successFlag: []
    };

    if (!isGuest()) {
      this.props.history.push('/invites');
    }

    const otp = this.props.match.params.otp;
    if (!isUndefined(otp)) {
      this.props.sendOtp({ otp });
    }
  }

  componentDidMount() {
    const showPasswordChanged = !isUndefined(this.props.history.location.state)
      ? this.props.history.location.state.passwordChanged
      : false;
    if (showPasswordChanged) {
      this.setState({
        successFlag: [1]
      });
    }
  }

  onChangeEmail = e => {
    const result = {
      wasChanged: true,
      isInvalid: true
    };

    this.props.changeEmail({
      login: e.target.value
    });

    if (isRequired(e.target.value) && isEmail(e.target.value)) {
      result.isInvalid = false;
    }

    this.setState({
      email: result
    });

    this.validateForm();
  };

  onChangePassword = e => {
    const result = {
      wasChanged: true,
      isInvalid: true
    };

    this.props.changePassword({
      password: e.target.value
    });

    if (isRequired(e.target.value)) {
      result.isInvalid = false;
    }

    this.setState({
      password: result
    });

    this.validateForm();
  };

  removeFlag = id => {
    const { changeFlags, flags } = this.props;

    changeFlags({
      flags: flags.filter(v => v !== id)
    });
  };

  removeSuccessFlag = () => {
    this.setState({
      successFlag: []
    });
  };

  validateForm = () => {
    const email = !this.state.email.isInvalid && this.state.email.wasChanged;
    const password = !this.state.password.isInvalid && this.state.password.wasChanged;

    this.setState({
      isFormValid: email && password
    });
  };

  renderContent = () => {
    const {
      props: { otpLoading }
    } = this;

    if (otpLoading) {
      return <Spinner height="80vh" />;
    }

    return (
      <Flex>
        <FlexItem align="center" hideOnMobile>
          <img src={Image} srcSet={`${Image2x} 2x`} alt="" />
        </FlexItem>
        <FlexItem>
          <Title>Вход</Title>
          {/* <P marginTop="3px" color="#6B778C">
            Еще не зарегистрированы? <Link to="/signup">Регистрация</Link>
          </P> */}

          <Form>
            <FieldGroup>
              <Field>
                <TextField
                  isInvalid={this.state.email.isInvalid}
                  required
                  label="Email"
                  name="email"
                  onBlur={this.onChangeEmail}
                  onChange={this.onChangeEmail}
                  placeholder=""
                  value={this.props.login}
                  shouldFitContainer
                />
              </Field>
              <Field>
                <TextField
                  isInvalid={this.state.password.isInvalid}
                  required
                  label="Пароль"
                  name="password"
                  onBlur={this.onChangePassword}
                  onChange={this.onChangePassword}
                  placeholder=""
                  value={this.props.password}
                  shouldFitContainer
                  type="password"
                />
                <P marginTop="5px">
                  <Link to="/recover">Забыли пароль?</Link>
                </P>
              </Field>
            </FieldGroup>

            <FieldGroup>
              <ButtonField>
                <Button
                  appearance="primary"
                  isDisabled={!this.state.isFormValid}
                  onClick={this.props.loginRequest}
                >
                  Войти
                </Button>
              </ButtonField>
            </FieldGroup>
          </Form>
          <ContactsWrapper>
            <a href="https://watbot.ru/w/D7v" target="_blank" rel="noopener noreferrer">
              <Telegram />
            </a>
          </ContactsWrapper>
        </FlexItem>

      </Flex>
    );
  };

  render() {
    const {
      props: { error, flags },
      renderContent
    } = this;

    return (
      <Page>
        <Helmet>
          <title>Вход</title>
        </Helmet>

        {renderContent()}

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {flags.map(id => (
            <Flag
              description={error}
              icon={<Error label="Error" primaryColor={colors.R300} />}
              id={id}
              isDismissAllowed
              key={`${id}`}
              title="Ошибка"
            />
          ))}
        </FlagGroup>

        <FlagGroup onDismissed={name => this.removeSuccessFlag(name)}>
          {this.state.successFlag.map(id => (
            <Flag
              description="Теперь вы можете использовать свои email и новый пароль, чтобы войти."
              icon={<Tick label="Success" />}
              id={id}
              isDismissAllowed
              key={`${id}`}
              title="Пароль успешно изменен"
            />
          ))}
        </FlagGroup>
      </Page>
    );
  }
}

Login.propTypes = {
  changeEmail: PropTypes.func,
  changeFlags: PropTypes.func,
  changePassword: PropTypes.func,
  error: PropTypes.string,
  flags: PropTypes.array,
  login: PropTypes.string,
  loginRequest: PropTypes.func,
  otpLoading: PropTypes.bool.isRequired,
  password: PropTypes.string,
  sendOtp: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    changeEmail: value => dispatch(changeLogin(value)),
    changeFlags: value => dispatch(changeFlags(value)),
    changePassword: value => dispatch(changePassword(value)),
    loginRequest: () => dispatch(loginRequest()),
    sendOtp: value => dispatch(sendOtp(value))
  };
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectError(),
  flags: makeSelectFlags(),
  login: makeSelectLogin(),
  password: makeSelectPassword(),
  otpLoading: makeSelectOtpLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'login', reducer });
const withSaga = injectSaga({ key: 'login', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Login);
