import React from 'react';
import { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import service from 'utils/checkService';

let HelpDropdown = () => (
  <div>
    <DropdownItemGroup title="Поддержка">
      <DropdownItem href="https://watbot.ru/w/D7v" target="_blank" rel="noopener noreferrer">
        Связаться в Telegram
      </DropdownItem>
    </DropdownItemGroup>
    <DropdownItemGroup title="Правовая информация">
      <DropdownItem href="/documents/2" rel="noopener noreferrer" target="_blank">
        Правила оплаты
      </DropdownItem>
      <DropdownItem href="/documents/1" rel="noopener noreferrer" target="_blank">
        Договор-оферта
      </DropdownItem>
      <DropdownItem href="/documents/3" rel="noopener noreferrer" target="_blank">
        Политика конфиденциальности
      </DropdownItem>
      <DropdownItem href="/documents/4" rel="noopener noreferrer" target="_blank">
        Пользовательское соглашение
      </DropdownItem>
    </DropdownItemGroup>
  </div>
);

if (service === "my-yadro") {
  HelpDropdown = () => (
    <div>
      <DropdownItemGroup title="Поддержка">
        <DropdownItem href="https://watbot.ru/w/D7v" target="_blank" rel="noopener noreferrer">
          Связаться в Telegram
        </DropdownItem>
      </DropdownItemGroup>
      <DropdownItemGroup title="Правовая информация">
        <DropdownItem href="https://my-yadro.ru/policy" rel="noopener noreferrer" target="_blank">
          Политика обработки персональных данных
        </DropdownItem>
      </DropdownItemGroup>
    </div>
  )
}

export default HelpDropdown;
