import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Page from '@atlaskit/page';
import Form from '@atlaskit/form';
import Button from '@atlaskit/button';
import { AutoDismissFlag as Flag, FlagGroup } from '@atlaskit/flag';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import { Link } from 'react-router-dom';
import { pageHostUrl } from 'utils/serverConfig';

import { isRequired, isEmail } from 'utils/validators';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import FacebookAuth from 'components/FacebookAuth';
import GoogleAuth from 'components/GoogleAuth';
import TextField from 'components/TextField';
import VkAuth from 'components/VkAuth';

import { changeFlags, changeLogin, changePassword, loginRequest } from './actions';

import { makeSelectError, makeSelectFlags, makeSelectLogin, makeSelectPassword } from './selectors';

import reducer from './reducer';
import saga from './saga';

import Image from './images/pic.png';
import Image2x from './images/pic-2x.png';

import AuthWrapper from './styled/AuthWrapper';
import ButtonField from './styled/ButtonField';
import Field from './styled/Field';
import FieldGroup from './styled/FieldGroup';
import Flex from './styled/Flex';
import FlexItem from './styled/FlexItem';
import Title from './styled/Title';
import ContactsWrapper from './styled/ContactsWrapper';
import P from '../../Login/styled/P';

import Telegram from "../../../promo/Footer/telegram";
import WhatsApp from "../../../promo/Footer/whatsapp";

class IndividualLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authErrorFlags: [],
      errorFlag: [1],
      email: {
        wasChanged: false,
        isInvalid: false,
        value: ''
      },
      password: {
        wasChanged: false,
        isInvalid: false,
        value: ''
      },
      isFormValid: true,
      successFlag: []
    };

    if (
      !(isNull(localStorage.getItem('staffKey')) || localStorage.getItem('staffKey') === 'null')
    ) {
      this.props.history.push('/individual/report');
    }

    if (!isUndefined(this.props.location.state) && this.props.location.state.showError) {
      this.state.authErrorFlags = [1];
      this.props.history.replace({
        ...this.props.history,
        state: {}
      });
    }
  }

  componentDidMount() {
    const showPasswordChanged = !isUndefined(this.props.history.location.state)
      ? this.props.history.location.state.passwordChanged
      : false;
    if (showPasswordChanged) {
      this.setState({
        successFlag: [1]
      });
    }
  }

  onChangeEmail = e => {
    const result = {
      wasChanged: true,
      isInvalid: true
    };

    this.props.changeEmail({
      login: e.target.value
    });

    if (isRequired(e.target.value) && isEmail(e.target.value)) {
      result.isInvalid = false;
    }

    this.setState({
      email: result
    });

    this.validateForm();
  };

  onChangePassword = e => {
    const result = {
      wasChanged: true,
      isInvalid: true
    };

    this.props.changePassword({
      password: e.target.value
    });

    if (isRequired(e.target.value)) {
      result.isInvalid = false;
    }

    this.setState({
      password: result
    });

    this.validateForm();
  };

  removeFlag = id => {
    const { changeFlags, flags } = this.props;

    changeFlags({
      flags: flags.filter(v => v !== id)
    });
  };

  removeAuthFlag = id => {
    this.setState({
      authErrorFlags: this.state.authErrorFlags.filter(v => v !== id)
    });
  };

  validateForm = () => {
    const email = !this.state.email.isInvalid && this.state.email.wasChanged;
    const password = !this.state.password.isInvalid && this.state.password.wasChanged;

    this.setState({
      isFormValid: email && password
    });
  };

  render() {
    const { error, flags } = this.props;
    const { authErrorFlags } = this.state;

    return (
      <Page>
        <Helmet>
          <title>Вход</title>
        </Helmet>

        <Flex>
          <FlexItem align="center" hideOnMobile>
            <img src={Image} srcSet={`${Image2x} 2x`} alt="" />
          </FlexItem>
          <FlexItem>
            <Title>Вход</Title>
            <P marginTop="3px" color="#6B778C">
              Еще не зарегистрированы? <Link to="/invite/s">Регистрация</Link>
            </P>

            <Form>
              <FieldGroup>
                <Field>
                  <TextField
                    isInvalid={this.state.email.isInvalid}
                    required
                    label="Email"
                    name="email"
                    onBlur={this.onChangeEmail}
                    onChange={this.onChangeEmail}
                    placeholder=""
                    value={this.props.login}
                    shouldFitContainer
                  />
                </Field>
                <Field>
                  <TextField
                    isInvalid={this.state.password.isInvalid}
                    required
                    label="Пароль"
                    name="password"
                    onBlur={this.onChangePassword}
                    onChange={this.onChangePassword}
                    placeholder=""
                    value={this.props.password}
                    shouldFitContainer
                    type="password"
                  />
                  <P marginTop="5px">
                    <Link to="/recover">Забыли пароль?</Link>
                  </P>
                </Field>
              </FieldGroup>

              <FieldGroup>
                <ButtonField width="100%">
                  <Button
                    appearance="primary"
                    isDisabled={!this.state.isFormValid}
                    onClick={this.props.loginRequest}
                  >
                    Войти
                  </Button>
                </ButtonField>
              </FieldGroup>
              {/*<div>*/}
              {/*  <P marginTop="20px">или войдите через соц.сети</P>*/}
              {/*  <AuthWrapper>*/}
              {/*    <FacebookAuth*/}
              {/*      link={`https://www.facebook.com/v3.2/dialog/oauth?client_id=2364882473743013&redirect_uri=${pageHostUrl}/facebook&response_type=code&scope=email`}*/}
              {/*    />*/}
              {/*    <VkAuth*/}
              {/*      link={`https://oauth.vk.com/authorize?client_id=6880349&display=page&redirect_uri=${pageHostUrl}/vk&scope=email&response_type=code&v=5.92`}*/}
              {/*    />*/}
              {/*    <GoogleAuth*/}
              {/*      link={`https://accounts.google.com/o/oauth2/v2/auth?scope=email&access_type=offline&include_granted_scopes=true&redirect_uri=${encodeURI(*/}
              {/*        `${pageHostUrl}/google`*/}
              {/*      )}&response_type=code&client_id=321511513968-450ftjk9rtm3lrvgon7fktbqg7ki7bmj.apps.googleusercontent.com`}*/}
              {/*    />*/}
              {/*  </AuthWrapper>*/}
              {/*  <ContactsWrapper>*/}
              {/*    <a href="https://t.me/eg0rgaiduk" target="_blank" rel="noopener noreferrer">*/}
              {/*      <Telegram />*/}
              {/*    </a>*/}
              {/*    <a href="https://api.whatsapp.com/send/?phone=79830007000" target="_blank" rel="noopener noreferrer">*/}
              {/*      <WhatsApp />*/}
              {/*    </a>*/}
              {/*  </ContactsWrapper>*/}
              {/*</div>*/}
            </Form>
            <ContactsWrapper>
            <a href="https://watbot.ru/w/D7v" target="_blank" rel="noopener noreferrer">
              <Telegram />
            </a>
          </ContactsWrapper>
          </FlexItem>
        </Flex>

        <FlagGroup onDismissed={name => this.removeFlag(name)}>
          {flags.map(id => (
            <Flag
              description={error}
              icon={<Error label="Error" primaryColor={colors.R300} />}
              id={id}
              isDismissAllowed
              key={`${id}`}
              title="Ошибка"
            />
          ))}
        </FlagGroup>

        <FlagGroup onDismissed={name => this.removeAuthFlag(name)}>
          {authErrorFlags.map(id => (
            <Flag
              description="Пользователь с таким e-mail уже зарегистрирован в системе."
              icon={<Error label="Error" primaryColor={colors.R300} />}
              id={id}
              isDismissAllowed
              key={`${id}`}
              title="Ошибка"
            />
          ))}
        </FlagGroup>
      </Page>
    );
  }
}

IndividualLogin.propTypes = {
  changeEmail: PropTypes.func,
  changeFlags: PropTypes.func,
  changePassword: PropTypes.func,
  error: PropTypes.string,
  flags: PropTypes.array,
  login: PropTypes.string,
  loginRequest: PropTypes.func,
  password: PropTypes.string
};

function mapDispatchToProps(dispatch) {
  return {
    changeEmail: value => dispatch(changeLogin(value)),
    changeFlags: value => dispatch(changeFlags(value)),
    changePassword: value => dispatch(changePassword(value)),
    loginRequest: () => dispatch(loginRequest())
  };
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectError(),
  flags: makeSelectFlags(),
  login: makeSelectLogin(),
  password: makeSelectPassword()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'individualLogin', reducer });
const withSaga = injectSaga({ key: 'individualLogin', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(IndividualLogin);
